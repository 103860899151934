import logo from './logo.svg';
import './App.css';
import './components/css/layout/header.css'
import {
  Routes,
  Route,
  Link,
  Outlet,
  BrowserRouter
} from "react-router-dom";
import RoutePage from './components/route/Route'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="*" element={<RoutePage />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}


export default App;
