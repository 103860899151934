import {ACTION_SYSTEM} from '../action/actionUsers/ActionTypes'

const initialState = {
  system_apps: [],
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SYSTEM:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default systemReducer;
