import Cover from "./Cover";
import Login from "./Login";
const MainLogin = () => {
  return (
    <div>
      <Login/>
      <Cover/>
    </div>
  );
};

export default MainLogin;
