import logo from "../../../logo.svg";
import Logo from "../../assets/header/workflow.jpg";
import Notification from "../../assets/header/notification.png";
import "../../css/layout/header.css";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  const dispatch_data = (type, data) => {
    dispatch({
      type: type,
      data: data,
    });
  };
  const logout = () => {
    document.cookie = "token=" + ";expires";
    localStorage.clear();
    dispatch_data('user_account',[])
  };
  return (
    <div className="header">
      <div className="headerLeft">
        <img className="imgLogo" src={Logo} />
        <Typography>Workflow </Typography>
      </div>
      <div className="headerRight">
        <span className="curveContainer">
          <p>HEAD OFFICE GREENZONE</p>
        </span>
        <span>
          <i className="fa fa-bell notification"></i>
        </span>
        <span className="initialName">
          <p
            onClick={() => {
              logout();
            }}
          >
            J
          </p>
        </span>
      </div>
    </div>
  );
};

export default Header;
