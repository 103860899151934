import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import BGimg from "../../assets/images/bgboxbox.png";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import React from "react";

import "../../css/layout/system.css";

var createHost = require("cross-domain-storage/host");
// var createGuest = require("cross-domain-storage/guest");
var storageHost = createHost([
  {
    origin: "http://localhost:3000",
    allowedMethods: ["get", "set", "remove"],
  },
  {
    origin: "http://localhost:3001",
    allowedMethods: ["get"],
  },
]);
const Systems = () => {
  const system_apps = useSelector((state) => state.SystemReducer.system_apps);
  const jwt = useSelector((state) => state.LoginReducer.jwt);
  const useraccount = useSelector((state) => state.LoginReducer.user_account);
  const [otherSide, setotherSide] = React.useState("");
  const details = (val) => {
    let data = {
      useraccount: useraccount[0],
      jwp: jwt,
      selectedApp: val,
    };
    localStorage.setItem("key", JSON.stringify(data));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <Typography>Our Solutions</Typography>
        {/* <p>key: {localStorage.getItem("key")}</p> */}
        <div className="system-container text-center">
          {system_apps.map((val, index) => {
            return (
              <Card key={index}>
                <div style={{ position: "relative" }}>
                  <CardActionArea
                    onClick={() => {
                      details(val);
                    }}
                    href={val.app_url}
                    target="_blank"
                  >
                    <CardMedia component="img" height="80" image={BGimg} />
                    <CardContent>
                      <Avatar
                        style={{
                          position: "absolute",
                          left: 5,
                          top: 55,
                          borderStyle: "solid",
                          borderColor: "transparent",
                          backgroundColor: "transparent",
                          color: "blue",
                          margin: 10,
                        }}
                      >
                        Logo
                      </Avatar>
                      <Typography variant="subtitle1">
                        {val.app_name}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </div>
              </Card>
            );
          })}
        </div>
      </Grid>
      <Grid item xs={2}>
        <p>{otherSide}</p>
      </Grid>
    </Grid>
  );
};

export default Systems;
