import { Navigate, Outlet } from "react-router-dom";
import LoginPage from '../login/main_login'
const useAuth = () => {
  const user = { loggedIn: true };
  return user && user.loggedIn;
};


const ProtectedRoutes = () => {
  const isAuth = useAuth();
  console.log(isAuth)
  return isAuth ? <Outlet /> :  <LoginPage />;
};



export default ProtectedRoutes;